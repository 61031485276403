import { richText } from '@/utils/html';
import { productThumb } from '@/utils/adapters/product';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export default ({ primary }, pageData, { $enhancedLinkSerializer }) => {
  return {
    type: primary.type || 'standard',
    textContent: richText(primary.content),
    product:
      primary.product.id &&
      productThumb(primary.product, $enhancedLinkSerializer),
    image: primary.image ? imageAdapter(primary.image) : null,
  };
};
